import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import SEO from "components/SEO";
import "./inputs.css";
import { rhythm } from "utils/typography";
import inputsImage from "./background2.jpg";

function Newsletter({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout
      location={location}
      title={siteTitle}
      showHeader={true}
      showFooter={true}
      fullWidth={false}
    >
      <SEO title="Inputs" description="We are as good as our inputs " />
      <img src={inputsImage} alt="a garden taken one year apart" />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: rhythm(40),
          padding: `0`
        }}
      >
        <h1
          style={{
            marginTop: `3rem`,
            marginBottom: `1rem`
          }}
        >
          We&apos;re as good as our inputs
        </h1>
        <p>
          To illustrate this principle, this is the photo I took of the exact same location one year
          apart.{" "}
        </p>
        <p>
          A year&apos;s worth of dedicated effort and quality inputs can have a huge impact on your
          life. The choice is yours whether you want to end up on the left or right of this photo a
          year from now.
        </p>
      </div>
    </Layout>
  );
}

Newsletter.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Newsletter;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

